import React, { useEffect } from "react";
import style from "./success.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateProfile } from "../../actions/user";
import { baseUrl } from "../url";
import { useState } from "react";
import PlaySound from "../Audio/PlaySound";
import PlayAudio from "../Audio/PlayAudio";
import { music } from "../../assests/Musics/allMusic";
import { Howl, Howler } from "howler";
import succss from "../../assests/Stripe Payment Confirmed Screen.png"

const Success = ({ gameMusic, setGameMusic, gameSound, setGameSound }) => {
  // const baseUrl = "https://uat.wincha-online.com"
  const userId = JSON.parse(localStorage.getItem("user"));
  const status = localStorage.getItem("PointStatus");
  const { configuration } = useSelector((state) => state.configuration);
  // const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const checkoutId = search.split("=");
  // const sessionId = checkoutId[checkoutId.length - 1];
  const [playAudio, setPlayAudio] = useState("");

  let audioStatus = localStorage.getItem("sound");
  const nav = localStorage.getItem("navLink");
  const [sessionId, setSessionId] = useState("");

  const gameData=JSON.parse(localStorage.getItem("gameData"))

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (activeStatus == false) {
  //       navigate("/prizes");
  //       window.location.reload()
  //     }
  //   }, 5000);
  // }, []);

  const playCoinDrop = () => {
    if (audioStatus === 1 || audioStatus === "1") {
      const sound = new Howl({
        src: [music.CoinDrop],
        autoplay: true,
        volume: 1.0,
        onend: () => {
          console.log("Sound finished playing");
        },
      });
      sound.play();
    }
  };

  const token = JSON.parse(localStorage.getItem("token"));
  const stripeId = localStorage.getItem("StripeId");
  async function addVip() {
    const vipData2 = {
      mode: "payment",
      amount: `${configuration.VIP_SUBSCRIPTION}`,
      quantity: 1,
      success_url: "http://localhost:8980/sucess",
      cancel_url: "http://localhost:8980/sucess",
      currency: "usd",
      product: "vip",
      payment_mode: "vip",
      user_id: userId,
      credict_point: "",
    };
    const vipData = {
      user_id: userId,
      vip: true,
      source: "web",
      amount: `${configuration.VIP_SUBSCRIPTION}`,
      gateway: "stripe",
    };
    await fetch(`${baseUrl}/user/membership/add`, {
      method: "PUT",
      body: JSON.stringify(vipData2),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(vipData);
        // const paymentSuccess = data.data[data.data.length-1].status
        // const product = data.data[data.data.length-1].product
        // if(paymentSuccess==="succeeded"){
        //     addPoint(data.data[data.data.length-1].product)
        // }
        // if(product==="Vip"){
        //   addVip()
        // addPoint("500")
        // }

        console.log(data.data[data.data.length - 1].status);
        console.log(data);
      });
  }
  async function checkoutStripe() {
    console.log(stripeId);
    await fetch(`${baseUrl}/points/check/stripe/status`, {
      method: "POST",
      body: JSON.stringify({
        id: sessionId,
        point_status: status,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // const paymentSuccess = data.data[data.data.length-1].status
        // if(paymentSuccess==="succeeded"||paymentSuccess==="complete"){
        //     addPoint(data.data[data.data.length-1].product)
        // }
        // const product = data.data[data.data.length-1].product
        //  if(product==="Vip"){
        //   addVip()
        // }
        // console.log(data.data[data.data.length-1].status)
        if (data.point_status === "ticket") {
          setPlayAudio(music.CoinDrop, "boing played");
        }
        console.log(data);
      });
  }


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    console.log(sessionId);
    setSessionId(sessionId);
  }, [location]);


  useEffect(() => {
    checkoutStripe();
  }, [sessionId]);

  return (
    <div className={style.Container}>
      <PlayAudio
        gameMusic={gameMusic}
        setGameMusic={setGameMusic}
        gameSound={gameSound}
        setGameSound={setGameSound}
      />
      {playAudio ? (
        <PlaySound
          setPlayAudio={setPlayAudio}
          src={playAudio}
          gameMusic={gameMusic}
          setGameMusic={setGameMusic}
          gameSound={gameSound}
          setGameSound={setGameSound}
        />
      ) : (
        ""
      )}
      <div className={style.popup}>
        <div className={style.imageSection}
          onClick={() => {
            playCoinDrop();

            if (nav === "games") {
              navigate(`/game/${gameData.slug}`, {
                state: {
                  game: gameData,
                  cateogry: gameData.category.split(',')[0].trim(),
                },
              });
              console.log(nav);
            } else if (nav === "cart") {
              navigate("/cart");
            } else if (nav === "profile") {
              navigate("/profile");
            }
            else if(nav==="ticket"){
              navigate("/tickets")
            }
            else {
              navigate("/prizes");
            }
          }}
        >
          <img src={succss} alt="" />
        </div>
        {/* <div className={style.confirm}>
        </div> */}
      </div>
      {/* <div className={style.M_All}>
        <div className={style.Mpopup}>
          <div className={style.Mconfirm}>
            <button
                onClick={() => {
                  playCoinDrop();
    
                  if (nav === "games") {
                    navigate("/prizes");
                    console.log(nav);
                  } else if (nav === "cart") {
                    navigate("/cart");
                  }else if (nav === "profile") {
                    navigate("/profile");
                  } 
                  else {
                    navigate("/prizes");
                  }
                }}
            >
              
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Success;
